<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('服务管理.新闻管理.标题')" prop="title" >
        <a-input v-model="form.title" :placeholder="$t('服务管理.新闻管理.请输入标题')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.新闻管理.封面')" prop="conver" >
        <file-upload type="image" :defaultList="form.converArr" :count="1"
                     @input="getImg($event, 'conver')"></file-upload>
      </a-form-model-item>
      <a-form-model-item :label="$t('服务管理.新闻管理.新闻内容')" prop="content" >
        <editor v-model="form.content" />
      </a-form-model-item>
<!--      <a-form-model-item label="浏览次数" prop="browseNum" >
        <a-input v-model="form.browseNum" placeholder="请输入浏览次数" />
      </a-form-model-item>
      <a-form-model-item label="点赞次数" prop="likeNum" >
        <a-input v-model="form.likeNum" placeholder="请输入点赞次数" />
      </a-form-model-item>
      <a-form-model-item label="评论次数" prop="commentNum" >
        <a-input v-model="form.commentNum" placeholder="请输入评论次数" />
      </a-form-model-item>
      <a-form-model-item label="转发次数" prop="forwardNum" >
        <a-input v-model="form.forwardNum" placeholder="请输入转发次数" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getNews, addNews, updateNews } from '@/api/news/news'
import Editor from '@/components/Editor'
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      converArr: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        title: null,

        conver: null,

        content: null,

        browseNum: null,

        likeNum: null,

        commentNum: null,

        forwardNum: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        conver: [
          { required: true, message: '封面不能为空', trigger: 'blur' }
        ],
        /*browseNum: [
          { required: true, message: '浏览次数不能为空', trigger: 'blur' }
        ],
        likeNum: [
          { required: true, message: '点赞次数不能为空', trigger: 'blur' }
        ],
        commentNum: [
          { required: true, message: '评论次数不能为空', trigger: 'blur' }
        ],
        forwardNum: [
          { required: true, message: '转发次数不能为空', trigger: 'blur' }
        ]*/
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        title: null,
        conver: null,
        converArr: [],
        content: null,
        browseNum: null,
        likeNum: null,
        commentNum: null,
        forwardNum: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getNews({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        // 图片回显
        let that = this;
        this.form.converArr = []
        if (this.form.conver) {
          this.form.converArr.push({
            uid: guid(8, 10),
            name: that.form.conver,
            status: 'done',
            halfUrl: that.form.conver,
            url: that.form.conver,
            path: that.form.conver
          })
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateNews(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addNews(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
