import request from '@/utils/request'


// 查询新闻列表
export function listNews(query) {
  return request({
    url: '/news/news/list',
    method: 'get',
    params: query
  })
}

// 查询新闻分页
export function pageNews(query) {
  return request({
    url: '/news/news/page',
    method: 'get',
    params: query
  })
}

// 查询新闻详细
export function getNews(data) {
  return request({
    url: '/news/news/detail',
    method: 'get',
    params: data
  })
}

// 新增新闻
export function addNews(data) {
  return request({
    url: '/news/news/add',
    method: 'post',
    data: data
  })
}

// 修改新闻
export function updateNews(data) {
  return request({
    url: '/news/news/edit',
    method: 'post',
    data: data
  })
}

// 删除新闻
export function delNews(data) {
  return request({
    url: '/news/news/delete',
    method: 'post',
    data: data
  })
}
